<template>
    <div>
        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container">
        <tool-profile :toolId="toolId" />
        </div>

    </div>
    
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import ToolProfile from '@/components/tools/ToolProfile.vue'

export default {
    components: {
        Navbar,
        ToolProfile,
    },


    computed: {
        toolId() {
            return parseInt(this.$route.params.toolId);
        }
    },

}
</script>

<style>

</style>