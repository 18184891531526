<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                QR kod alata <template v-if="tool">{{tool.name}}</template>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <template v-if="tool">

                    <qrcode-vue v-if="tool" :value="qrData" size="200" level="H"></qrcode-vue>
                </template>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

    import { Modal } from 'bootstrap'
    import QrcodeVue from 'qrcode.vue'


export default {

    components: {
        QrcodeVue
    },
    
    props: {
        modalId: {
            type: String,
            default: "",
        },

        tool: {
            type: Object,
        }
    },

    data() {
        return {
            modal: undefined,
            scanning: false,
        }
    },

    computed: {

        qrData() {
            if (this.tool.code) return this.tool.code;
            return "TOOLID|" + this.tool.id
        }
    },

    methods: {

    },

    mounted() {
        let modalElement = document.getElementById(this.modalId);
        this.modal = Modal.getInstance(modalElement);
    }

}
</script>

<style>

</style>