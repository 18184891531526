<template>
    <div class="uk-container uk-padding">
        <div class="uk-timeline">

            <div v-for="timeline_group, timeline_key in timeline" :key="timeline_key" class="uk-timeline-item">
                <div class="uk-timeline-icon">
                    <span class="uk-badge"><span uk-icon="check"></span></span>
                </div>
                <div class="uk-timeline-content">
                    <div class="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                        <div class="uk-card-header">
                            <div class="uk-grid-small uk-flex-middle" uk-grid>

                                <h3 class="uk-card-title"><time datetime="2020-07-08">{{formatLongDayMonth(timeline_key)}}</time></h3>
                            </div>
                        </div>
                        <div class="uk-card-body">
                            <template v-for="timeline_data, index in timeline_group">
                                <div :key="timeline_key + '-' +index" class="transfer-timeline mb-3">
                                    
                                    
                                    
                                    <template v-if="timeline_data.timeline_type === 'HISTORY' && timeline_data.history_changed && timeline_data.history_changed.some(r => ['warehouse', 'worker'].includes(r))">
                                        <small class="time">{{formatTime(timeline_data.timeline_date)}}</small>
                                        <div class="d-flex">
                                            <div class="from d-flex align-items-center flex-wrap">
                                                <template v-if="timeline_data.last">
                                                    
                                                    <template v-if="timeline_data.last.location_status === 1">
                                                        <div class="numberCircle">{{timeline_data.last.warehouse_obj.name.slice(0,1)}}</div>
                                                        <span class="title">{{timeline_data.last.warehouse_obj.name}}</span>
                                                    </template>
                                                    <template v-else-if="timeline_data.last.location_status === 2">
                                                        <div class="image-cell">
                                                            <img v-if="timeline_data.last.worker_obj.avatar_url" :src="timeline_data.last.worker_obj.avatar_url" alt="Slika" />
                                                            <div v-else class="numberCircle">{{timeline_data.last.worker_obj.first_name.slice(0,1)}}</div>
                                                        </div>
                                                        <span class="title">{{timeline_data.last.worker_obj.first_name}} {{timeline_data.last.worker_obj.last_name}}</span>   
                                                    </template>
                                                    <template v-else>
                                                        <div class="numberCircle">/</div>
                                                        <span class="title"></span>
                                                    </template>
                                                </template>

                                            </div>




                                            <font-awesome-icon class="right-arrow-icon" size="3x" icon="arrow-right" />



                                            <div class="from d-flex align-items-center flex-wrap">

                                                <template v-if="timeline_data.location_status === 1">
                                                    <div class="numberCircle">{{timeline_data.warehouse_obj.name.slice(0,1)}}</div>
                                                    <span class="title">{{timeline_data.warehouse_obj.name}}</span>
                                                </template>
                                                <template v-else-if="timeline_data.location_status === 2">
                                                    <div class="image-cell">
                                                        <img v-if="timeline_data.worker_obj.avatar_url" :src="timeline_data.worker_obj.avatar_url" alt="Slika" />
                                                        <div v-else class="numberCircle">{{timeline_data.worker_obj.first_name.slice(0,1)}}</div>
                                                    </div>
                                                    <span class="title">{{timeline_data.worker_obj.first_name}} {{timeline_data.worker_obj.last_name}}</span>
                                                </template>
                                                <template v-else>
                                                    <div class="numberCircle">/</div>
                                                    <span class="title"></span>
                                                </template>
                                            </div>
                                        </div>



                                        <!-- <div class="history-changed">
                                            <h5 v-if="timeline_data.history_change_reason">Komentar: {{timeline_data.history_change_reason}}</h5>
                                            <h6>Izmenjeno: <span class="badge bg-secondary" v-for="changed, index in timeline_data.history_changed" :key="index">{{historyFields[changed]}} </span></h6>
                                        </div> -->
                                    </template>







                                    <template v-if="timeline_data.timeline_type === 'TRANSFER' && timeline_data.transfer_status !== 'TRANSFERRED'">
                                        <!-- TRANSFER -->

                                        <small class="time"> Transfer: {{formatTime(timeline_data.timeline_date)}}</small>
                                        <div class="d-flex" :class="{
                                                'declined': timeline_data.transfer_status === 'DECLINED',
                                                'in-transfer': timeline_data.transfer_status === 'IN_TRANSFER',
                                                'requested': timeline_data.transfer_status === 'REQUESTED',
                                                'transferred': timeline_data.transfer_status === 'TRANSFERRED',
                                            }">
                                            <div class="from d-flex align-items-center flex-wrap">
                                                <h4 class="me-4 fw-bold" v-if="timeline_data.transfer_status === 'DECLINED'">Odbijeno</h4>
                                                <h4 class="me-4 fw-bold" v-if="timeline_data.transfer_status === 'IN_TRANSFER'">Ceka se potvrda</h4>
                                                <h4 class="me-4 fw-bold" v-if="timeline_data.transfer_status === 'REQUESTED'">Zahtev</h4>

                                                <template v-if="timeline_data.from_user">
                                                    <div class="image-cell">
                                                        <img v-if="timeline_data.from_user_obj.avatar_url" :src="timeline_data.from_user_obj.avatar_url" alt="Slika" />
                                                        <div v-else class="numberCircle">{{timeline_data.from_user_obj.first_name.slice(0,1)}}</div>
                                                    </div>
                                                    <span class="title">{{timeline_data.from_user_obj.first_name}} {{timeline_data.from_user_obj.last_name}}</span>
                                                </template>
                                                <template v-else-if="timeline_data.from_warehouse">
                                                    <div class="numberCircle">{{timeline_data.from_warehouse_obj.name.slice(0,1)}}</div>
                                                    <span class="title">{{timeline_data.from_warehouse_obj.name}}</span>
                                                </template>
                                                <template v-else>
                                                    <div class="numberCircle">/</div>
                                                    <span class="title"></span>
                                                </template>
                                            </div>

                                            <font-awesome-icon class="right-arrow-icon" size="3x" icon="arrow-right" />

                                            <div class="from d-flex align-items-center flex-wrap">

                                                <template v-if="timeline_data.to_user">
                                                    <div class="image-cell">
                                                        <img v-if="timeline_data.to_user_obj.avatar_url" :src="timeline_data.to_user_obj.avatar_url" alt="Slika" />
                                                        <div v-else class="numberCircle">{{timeline_data.to_user_obj.first_name.slice(0,1)}}</div>
                                                    </div>
                                                    <span class="title">{{timeline_data.to_user_obj.first_name}} {{timeline_data.to_user_obj.last_name}}</span>
                                                </template>
                                                <template v-else-if="timeline_data.to_warehouse">
                                                    <div class="numberCircle">{{timeline_data.to_warehouse_obj.name.slice(0,1)}}</div>
                                                    <span class="title">{{timeline_data.to_warehouse_obj.name}}</span>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToolsService from '@/service/ToolsService.js';
    import UtilService from '@/service/UtilService.js';


    export default {
        props: {
            toolId: Number
        },

        data() {
            return {
                timeline: [],

                historyFields: {
                    "code": "QR-kod",
                    "name": "Naziv",
                    "manufacturer": "Proizvodjac",
                    "description": "Opis",
                    "value": "Vrednost",
                    "location_status": "Lokacija",
                    "image_url": "Slika",
                    "deleted": "Obrisan",
                    "type": "Tip",
                    "warehouse": "Skladište",
                    "worker": "Radnik",
                    "history_change_reason": "Komentar",
                },
            }
        },

        methods: {
            formatLongDayMonth: UtilService.formatLongDayMonth,
            formatTime: UtilService.formatTime,

            loadTimeline() {
                let data = {id: this.toolId}
                ToolsService.getToolTimeline(data).then(result => {
                    ToolsService.prepareToolsHistory(result.data)
                    this.timeline = result.data;
                    console.log(result);
                })
            },

            loadData() {
                this.loadTimeline();
            }

        },

        created() {
            this.loadData();
        },
    }
</script>

<style>
    /* .uk-timeline .uk-timeline-item .uk-card {
    } */

    .uk-timeline .uk-timeline-item {
        display: flex;
        position: relative;
    }

    .uk-timeline .uk-timeline-item::before {
        background: #dadee4;
        content: "";
        height: 100%;
        left: 19px;
        position: absolute;
        top: 20px;
        width: 2px;
            z-index: -1;
    }

    .uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
            margin-top: 20px;
        width: 40px;
        height: 40px;
    }

    .uk-timeline .uk-timeline-item .uk-timeline-content {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0 0 0 1rem;
    }

    .uk-margin-medium-bottom {
        margin-bottom: 40px!important;
    }
    .uk-overflow-auto {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .uk-card-default {
        background: #fff;
        color: #666;
        box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    }
    .uk-card {
        position: relative;
        box-sizing: border-box;
        transition: box-shadow .1s ease-in-out;
    }

    .uk-card-default .uk-card-header {
        border-bottom: 1px solid #e5e5e5;
    }

    .uk-overflow-auto>:last-child {
        margin-bottom: 0;
    }

    .uk-card-body {
        padding: 20px 20px;
    }
    .uk-card-header {
        padding: 10px 20px;
    }

    @media (min-width: 1200px) {
        .uk-card-body {
            padding: 40px 40px;
        }
        .uk-card-header {
            padding: 20px 40px;
        }
    }

    @media (max-width: 768px) {
        .uk-timeline-icon {
            display: none;
        }

        .uk-timeline-content {
            padding-left: 0 !important;
        }
    }

    .uk-card-body {
        padding: 30px 30px;
    }

    .uk-badge {
        box-sizing: border-box;
        min-width: 22px;
        height: 22px;
        padding: 0 5px;
        border-radius: 500px;
        vertical-align: middle;
        background: #1e87f0;
        color: #fff;
        font-size: .875rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .uk-link, a {
        color: #1e87f0;
        text-decoration: none;
        cursor: pointer;
    }


    /* .image-cell {
        width: 200px;
    } */

    .transfer-timeline .title {
        font-size: 1.5em;
    }

    .image-cell > img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: 1rem;
        display: inline-block;
    }

    .numberCircle {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 6px;
        margin-right: 1rem;

        background: #fff;
        border: 2px solid #666;
        color: rgb(255, 255, 255);
        background: #666;
        text-align: center;
        display: inline-block;

        font: 32px Arial, sans-serif;
    }

    .transfer-timeline .right-arrow-icon {
        margin: 0.2em 0.8em;
    }

    .transfer-timeline .time {
        font-size: 1rem;
        font-weight: bold;
    }

    .history-changed {
        padding: 10px 0;
    }

    .declined {background: rgba(255, 0, 0, 0.16);}
    .transferred {background: rgba(0, 255, 0, 0.037);}
</style>