<template>
    <div>
        <modal-add-edit-tool :thisModal="modalNewTool" modalId="modalNewTool" title="Novi alat" :editTool="toolProfile" @confirmed="confirmedTool" />
        <modal-tool-q-r modalId="modalToolQR" :tool="toolProfile"/>
        <modal-tool-transfer :thisModal="modalNewToolTransfer" modalId="modalNewToolTransfer" title="Transfer" :editTool="toolProfile" @confirmed="confirmedToolTransfer" />
        <modal-add-edit-tool-service :thisModal="modalAddEditToolService" modalId="modalAddEditToolService" title="Servis" :toolId="toolId" :tool="toolProfile" :editTool="toolService" @confirmed="confirmedToolService" />

        <div v-if="toolProfile" class="row mt-3 main-info">
            <div class="d-flex flex-wrap">
                <div class="flex-shrink-0 me-5 images-cover">
                    <silent-box :gallery="toolImagesSBox"></silent-box>
                    <!-- <template v-if="toolProfile.image_url">
                        <img class="tool-image" v-for="(image, index) in tool_images" :key="index" :src="image" alt="Slika" />

                    </template> -->
                    <font-awesome-icon class="tool-image" v-if="!toolProfile.image_url" size="2x" icon="image"></font-awesome-icon>

                </div>

                <div class="basic-info">
                    <h1>{{toolProfile.name}} <em v-if="toolProfile.identification">[{{toolProfile.identification}}]</em></h1>
                    <h4 v-if="toolProfile.manufacturer">Proizvodjac: {{toolProfile.manufacturer}}</h4>
                    <h4 v-if="toolProfile.value">Vrednost: {{toolProfile.value}} RSD</h4>
                    <!-- <p v-if="toolProfile.description">{{toolProfile.description}}</p> -->


                    <template v-if="toolProfile.location_status === 'NOT_ASSIGNED'">
                        <h2>Nedodeljen alat</h2>
                    </template>
                    <template v-else-if="toolProfile.location_status === 'WAREHOUSE'">
                        <h2>U magacinu: <strong>{{toolProfile.warehouse_obj.name}}</strong></h2>
                    </template>
                    <template v-else-if="toolProfile.location_status === 'WORKER'">
                        <h2>Koristi radnik: <strong>{{toolProfile.worker_obj.first_name}} {{toolProfile.worker_obj.last_name}}</strong></h2>
                    </template>
                    <template v-else>
                        <h2>Alat van upotrebe</h2>
                    </template>

                    <button v-if="isAdmin" class="btn btn-primary" @click="showEditToolModal">Izmeni</button>
                    <button class="btn btn-primary" @click="showToolTransferModal">Transfer alata</button>
                    <button v-if="isAdmin" class="btn btn-primary" @click="showAddEditToolServiceModal">Novi servis</button>
                    <button class="btn btn-primary" @click="showQR"><font-awesome-icon icon="qrcode" /></button>

                </div>

            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-8">
            </div>
        </div>

        <div class="row mt-5">



            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-vehicle-container" type="button" role="tab" aria-controls="home-vehicle-container" aria-selected="true" @click="changeTab('vehicleInfo', 'home-vehicle-container')">Informacije</button>
                </li>
                <li v-if="user.role === 'ADMIN'" class="nav-item" role="presentation">
                    <button class="nav-link" id="trip-tab" data-bs-toggle="tab" data-bs-target="#trip-vehicle-container" type="button" role="tab" aria-controls="trip-vehicle-container" aria-selected="true" @click="changeTab('vehicleTrip', 'trip-vehicle-container')">Istorija</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="fuel-tab" data-bs-toggle="tab" data-bs-target="#fuel-vehicle-container" type="button" role="tab" aria-controls="fuel-vehicle-container" aria-selected="false">Oštećenja</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="service-tab" data-bs-toggle="tab" data-bs-target="#service-vehicle-container" type="button" role="tab" aria-controls="service-vehicle-container" aria-selected="false">Servis</button>
                </li>
            </ul>
            <div class="tab-content mt-3" id="myTabContent">
                <div class="tab-pane fade show active" id="home-vehicle-container" role="tabpanel" aria-labelledby="home-tab">
                    <tool-info-table />
                </div>
                <div v-if="user.role === 'ADMIN'" class="tab-pane fade show" id="trip-vehicle-container" role="tabpanel" aria-labelledby="trip-tab">
                    <h1 class="mb-3">Istorija alata</h1>
                    <tool-timeline-history :toolId="toolId" />
                </div>
                <div class="tab-pane fade" id="fuel-vehicle-container" role="tabpanel" aria-labelledby="profile-tab">
                </div>
                <div class="tab-pane fade" id="service-vehicle-container" role="tabpanel" aria-labelledby="contact-tab">
                </div>
                
            </div>



        </div>
<!-- TOOOLS -->

    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    // import UtilService from '@/service/UtilService.js';
    import ModalAddEditTool from '@/components/modals/tools/ModalAddEditTool.vue'
    import ModalToolTransfer from '@/components/modals/tools/ModalToolTransfer.vue'

    import ToolTimelineHistory from '@/components/tools/ToolTimelineHistory.vue';
    import { Modal } from 'bootstrap'
    import ModalToolQR from '@/components/modals/tools/ModalToolQR.vue';
    import ModalAddEditToolService from '@/components/modals/tools/ModalAddEditToolService.vue';
    import ToolInfoTable from '@/components/tools/ToolInfoTable.vue';

    export default {
        components: {
            ToolTimelineHistory,
            ModalAddEditTool,
            ModalToolQR,
            ModalToolTransfer,
            ModalAddEditToolService,
            ToolInfoTable
        },
   
        props: {
            toolId: Number
        },
        
        data() {
            return {
                toolService:undefined,

                modalNewTool: null,
                modalToolQR: null,
                modalNewToolTransfer: null,
                modalAddEditToolService: null,


            }
        },


        computed: {
            ...mapState([
                'user',
                'toolProfile'
            ]),


            isAdmin() {
                if (this.user.role == "ADMIN") return true;
                return false;
            },


            tool_images() {
                let images = []
                if (this.toolProfile?.image_url){
                    images.push(this.toolProfile?.image_url)

                    this.toolProfile?.gallery?.forEach(img => {
                        images.push(img.url)
                    });
                }
                return images
            },

            toolImagesSBox() {
                let images = []
                let imageId = 1;
                if (this.toolProfile?.image_url){
                    let obj = {
                        src: this.toolProfile?.image_url,
                        thumbnailHeight: "200px",
                        thumbnailWidth: "200px",
                        description: "Slika " + imageId++
                    }

                    images.push(obj)

                    this.toolProfile?.gallery?.forEach(img => {
                        let obj = {
                            src: img.url,
                            thumbnailHeight: "200px",
                            thumbnailWidth: "200px",
                            description: "Slika " + imageId++
                        }

                        images.push(obj)
                    });
                }
                return images

            }

        },


        

        methods: {
            
            loadToolData() {
                this.$store.dispatch('loadToolProfile', {id: this.toolId});
            },

            loadData() {
                this.loadToolData();
            },


            showEditToolModal() {
                this.modalNewTool.show();
            },

            showToolTransferModal() {
                this.modalNewToolTransfer.show();
            },

            showAddEditToolServiceModal() {
                this.modalAddEditToolService.show();
            },


            showQR() {
                this.modalToolQR.show();
            },

            confirmedTool() {
                console.log("confirmed");
                this.loadData();
            },

            confirmedToolService() {
                console.log("confirmed");
                this.loadData();
            },

            confirmedToolTransfer() {
                this.loadData();
            },

        },



        created() {
            this.loadData();
        },

        mounted() {
            this.modalNewTool = new Modal(document.getElementById('modalNewTool'), {backdrop: 'static', keyboard: false});
            this.modalToolQR = new Modal(document.getElementById('modalToolQR'));
            this.modalNewToolTransfer = new Modal(document.getElementById('modalNewToolTransfer'), {backdrop: 'static', keyboard: false});
            this.modalAddEditToolService = new Modal(document.getElementById('modalAddEditToolService'), {backdrop: 'static', keyboard: false});
        },



    }
</script>

<style scoped>

    .main-info .tool-image {
        object-fit: cover;
        width: 200px;
        height: 200px;
    }

</style>
