<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div>
                        <form @submit.prevent="" id="tool-form">
                            <div class="container col-md-10 d-grid gap-2">
                                <div class="d-flex">
                                    <div v-if="tool.image_url" class="gallery-image">
                                        <img class="upload-preview main-image tool-image" :src="tool.image_url" alt="Slika">
                                        <div class="overlay" />
                                        <div class="img-btn-group">
                                            <button @click="deleteMainImage" class="btn"><font-awesome-icon icon="trash" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap">
                                    <div v-for="(image, index) in tool.gallery" :key="index" class="gallery-image">
                                        <img class="upload-preview tool-image" :src="image.url" :alt="'Slika ' + index + 1">
                                        <div class="overlay" />
                                        <div class="img-btn-group">
                                            <button @click="setAsMainImage(index)" class="btn"><font-awesome-icon icon="image" /></button>
                                            <button @click="deleteGaleryImage(index)" class="btn"><font-awesome-icon icon="trash" /></button>
                                        </div>
                                    </div>
                                </div>
                                
                                <p v-if="uploading">Dodaje se</p>
                                <div class="form-floating">
                                    <file-upload
                                        class="form-control"
                                        post-action="/upload/post"
                                        extensions="gif,jpg,jpeg,png,webp"
                                        accept="image/png,image/gif,image/jpeg,image/webp"
                                        :multiple="false"
                                        :size="1024 * 1024 * 10"
                                        v-model="file"
                                        @input-filter="inputFilter"
                                        @input-file="inputFile"
                                        ref="upload">
                                        <i class="fa fa-plus"></i>
                                        Dodaj sliku
                                    </file-upload>
                                </div>


                                <div class="d-flex">
                                    <div class="w-100">
                                        <div class="form-floating">
                                            <input list="inputNames" v-model="tool.name" class="form-control" type="text" placeholder="Bušilica" aria-label="Naziv alata" required>
                                            <label for="floatingInput">Naziv alata:</label>
                                            <div class="invalid-feedback">
                                                Naziv je obavezno polje.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-shrink-3">

                                        <div class="form-floating">
                                            <input v-model="tool.identification" class="form-control" type="text" placeholder="1" aria-label="Identifikacija">
                                            <label for="floatingInput">Identifikacija:</label>
                                            <div class="invalid-feedback">
                                                Identifikacija je obavezno polje.
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-floating">

                                    <select v-model="tool.type" class="form-select" aria-label="Default select example">
                                        <option disabled :value="undefined">Grupa alata</option>
                                        <option :value="null">Nema grupu</option>
                                        <option v-for="toolType in toolTypes" :key="toolType.id" :value="toolType.id">{{toolType.name}}</option>
                                    </select>
                                    <label for="floatingInput">Grupa alata:</label>
                                    <div class="invalid-feedback">
                                        Grupa alata je obavezno polje.
                                    </div>
                                </div>


                                <div class="form-floating">

                                    <input list="inputSubtypes" v-model="tool.sub_type" class="form-control" type="text" placeholder="Podgrupa" aria-label="Podgrupa alata">
                                    <label for="floatingInput">Podgrupa alata:</label>
                                    <div class="invalid-feedback">
                                        Podgrupa alata je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating">

                                    <input v-model="tool.code_name" class="form-control" type="text" placeholder="B-1" aria-label="Tipski naziv">
                                    <label for="floatingInput">Tipski naziv:</label>
                                    <div class="invalid-feedback">
                                        Tipski naziv je obavezno polje.
                                    </div>
                                </div>


                                <div class="form-floating">

                                    <input v-model="tool.serial_number" max="40" class="form-control" type="text" placeholder="B-1" aria-label="Serijski broj">
                                    <label for="floatingInput">Serijski broj:</label>
                                    <div class="invalid-feedback">
                                        Serijski broj mora biti kraci od 40 karaktera.
                                    </div>
                                </div>


                                <div class="form-floating">
                                    <input v-model="tool.manufacturer" class="form-control" type="text" placeholder="BOSCH" aria-label="Proizvodjač">
                                    <label for="floatingInput">Proizvođač:</label>
                                    <div class="invalid-feedback">
                                        Proizvodjač je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating">

                                    <input v-model="tool.year" min="1900" max="2050" class="form-control" type="number" placeholder="2022" aria-label="Godina proizvodnje">
                                    <label for="floatingInput">Godina proizvodnje:</label>
                                    <div class="invalid-feedback">
                                        Godina proizvodnje je obavezno polje.
                                    </div>
                                </div>


                                <div class="form-floating">
                                    <input v-model="tool.value" step=".01" class="form-control" type="number"  placeholder="5000 RSD" aria-label="Proizvodjač">
                                    <label for="floatingInput">Vrednost alata (RSD):</label>
                                    <div class="invalid-feedback">
                                        Vrednost alata je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating">
                                    <select v-model="tool.condition" class="form-select" aria-label="Stanje" required>
                                        <option value="WORKING">Ispravno</option>
                                        <option value="BROKEN">Neispravno</option>
                                        <option value="SPENT">Rashod</option>
                                    </select>
                                    <label for="floatingInput">Stanje:</label>
                                    <div class="invalid-feedback">
                                        Stanje alata je obavezno polje.
                                    </div>
                                </div>
                                
                                <div class="d-flex">
                                    <div class="w-100">
                                        <div class="form-floating">
                                            <input v-model="tool.code" class="form-control" type="text" placeholder="23212323" aria-label="Proizvodjač">
                                            <label for="floatingInput">Bar/QR kod:</label>
                                        </div>
                                    </div>

                                    <div class="p-2 flex-shrink-1">
                                        <button type="button" class="btn" @click="openCodeReader()"><font-awesome-icon size="2x" icon="qrcode" /></button>
                                    </div>
                                </div>
                                <div v-if="reader">
                                    <StreamBarcodeReader @decode="onDecode" @error="onError" />                                    
                                </div>

                                <div class="mb-3 form-floating">
                                    <textarea rows="5" class="form-control text-area-floating" v-model="tool.description" placeholder="Komentar" />
                                    <label for="woCode">Komentar</label>
                                </div>

                                <div v-if="!editTool" class="form-floating">
                                    <div class="form-check">
                                        <input v-model="tool.location_status" value="NOT_ASSIGNED" class="form-check-input" type="radio" name="location-status-radio" id="not-assigned-radio">
                                        <label class="form-check-label" for="not-assigned-radio">
                                            Nedodeljen alat
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="tool.location_status" value="WAREHOUSE" class="form-check-input" type="radio" name="location-status-radio" id="warehouse-radio" checked>
                                        <label class="form-check-label" for="warehouse-radio">
                                            U skladištu
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="tool.location_status" value="WORKER" class="form-check-input" type="radio" name="location-status-radio" id="worker-radio" checked>
                                        <label class="form-check-label" for="worker-radio">
                                            Kod radnika
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="tool.location_status" value="REMOVED" class="form-check-input" type="radio" name="location-status-radio" id="removed-radio" checked>
                                        <label class="form-check-label" for="removed-radio">
                                            Van upotrebe
                                        </label>
                                    </div>
                                </div>

                                <div class="form-floating" v-if="tool.location_status === 'WAREHOUSE'">

                                    <select v-model="tool.warehouse" class="form-select" aria-label="Default select example" required>
                                        <option disabled :value="undefined">Izaberi skladište</option>
                                        <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">{{warehouse.name}}</option>
                                    </select>
                                    <label for="floatingInput">Skladište:</label>
                                    <div class="invalid-feedback">
                                        Skladište je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating" v-if="tool.location_status === 'WORKER'">

                                    <select v-model="tool.worker" class="form-select" aria-label="Default select example" required>
                                        <option disabled :value="undefined">Izaberi zaposlenog</option>
                                        <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                                    </select>
                                    <label for="floatingInput">Zaposleni:</label>
                                    <div class="invalid-feedback">
                                        Zaposleni je obavezno polje.
                                    </div>
                                </div>

                                <datalist id="inputNames">
                                    <option v-for="toolName, index in toolsNames" :key="index" :value="toolName" />
                                </datalist>

                                <datalist id="inputSubtypes">
                                    <option v-for="toolsSubtype, index in toolsSubtypesNames" :key="index" :value="toolsSubtype" />
                                </datalist>

                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button :disabled="uploading" @click="submitTool()" type="button" class="btn btn-primary">
                        Potvrdi
                        <div v-if="uploading" class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import ToolsService from '@/service/ToolsService.js';
    import AwsService from '@/service/AwsService.js'
    import { StreamBarcodeReader } from "vue-barcode-reader"
    import UtilService from '@/service/UtilService.js';


    export default {

        props: {
            modalId: String,
            title: String,
            editTool: Object,
            thisModal: Object
        },

        components: {
            StreamBarcodeReader
        },

        data() {
            return {
                tool: {
                    id: undefined,
                    type: undefined,
                    sub_type: "",
                    name: "",
                    identification: null,
                    code_name: "",
                    code: "",
                    serial_number: "",
                    manufacturer: "",
                    year: null,
                    value: undefined,
                    condition: "WORKING",
                    location_status: "NOT_ASSIGNED",
                    image_url: "",
                    gallery: [],
                    worker: null,
                    warehouse: null,
                    description: "",
                },

                uploading: false,
                file: [],

                reader: false,

            }
        },

        computed: {
            ...mapState([
                'toolTypes',
                'warehouses',
                'allActiveUsers',
                'toolsNames',
                'toolsSubtypesNames'
            ]),

            
            employees() {
                let emp = UtilService.deepClone(this.allActiveUsers);
                return emp.sort((a, b) => a?.first_name?.localeCompare(b?.first_name));
            },

        },


        methods: {

            validate() {
                let form = document.getElementById("tool-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitTool() {
                if (!this.validate()) return

                if (this.editTool === undefined) this.addTool();
                else this.putTool();


            },

            putTool() {
                ToolsService.putTool(this.tool).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            addTool() {
                ToolsService.addTool(this.tool).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');

                })
            },

            openCodeReader() {
                this.reader = true;
            },
            

            initData() {
                if (this.editTool !== undefined) {
                    this.tool = {
                        id: this.editTool.id,
                        type: this.editTool.type,
                        sub_type: this.editTool.sub_type,
                        name: this.editTool.name,
                        identification: this.editTool.identification,
                        code_name: this.editTool.code_name,
                        code: this.editTool.code,
                        serial_number: this.editTool.serial_number,
                        manufacturer: this.editTool.manufacturer,
                        year: this.editTool.year,
                        value: this.editTool.value,
                        condition: this.editTool.condition,
                        location_status: this.editTool.location_status,
                        image_url: this.editTool.image_url,
                        gallery: this.editTool.gallery || [],
                        worker: this.editTool.worker,
                        warehouse: this.editTool.warehouse,
                        description: this.editTool.description,
                    }
                } else {
                    this.tool = {
                        id: undefined,
                        type: undefined,
                        sub_type: "",
                        name: "",
                        identification: null,
                        code_name: "",
                        code: "",
                        serial_number: "",
                        manufacturer: "",
                        year: null,
                        value: undefined,
                        condition: "WORKING",
                        location_status: "NOT_ASSIGNED",
                        image_url: "",
                        gallery: [],
                        worker: null,
                        warehouse: null,
                        description: "",
                    }
                }
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },

            inputFilter(newFile, oldFile, prevent) {
                console.log("FILTER");
                if (newFile && !oldFile) {
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                    }
                    
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                    }
                }
            },
            
            async inputFile(newFile, oldFile) {
                if (newFile && !oldFile) {
                    console.log('add', newFile)
                    this.uploadImage();
                }
                if (newFile && oldFile) {
                    console.log('update', newFile)
                    this.uploadImage();
                }
                if (!newFile && oldFile) {
                    // remove
                    console.log('remove', oldFile)
                }
            },

            async uploadImage() {
                this.uploading = true;
                console.log(this.file);
                let imageUrl = await AwsService.uploadFile(this.file[0].file).catch(error => {
                    alert("Neuspesno dodavanje")
                    console.log(error);
                });
                this.uploading = false;

                if (!this.tool.image_url)
                    this.tool.image_url = imageUrl;
                else {
                    let image = {
                        url: imageUrl
                    }
                    this.tool.gallery.push(image)
                }

            },

            deleteMainImage() {
                this.tool.image_url = "";
                if (this.tool.gallery?.length > 0) {
                    this.tool.image_url = this.tool.gallery[0]['url'];
                    this.tool.gallery.splice(0, 1);
                }
            },

            setAsMainImage(index) {
                this.tool.gallery.push({url: this.tool.image_url});
                this.tool.image_url = this.tool.gallery[index]['url'];
                this.tool.gallery.splice(index, 1);

            },

            deleteGaleryImage(index) {
                this.tool.gallery.splice(index, 1);
            },

            async loadAllToolTypes() {
                await this.$store.dispatch('getAllToolTypes')
            },
            async loadAllWarehouses() {
                await this.$store.dispatch('getAllWarehouses')
            },

            loadAllWorkers() {
                this.$store.dispatch('loadAllActiveUsers')
            },

            onDecode(data) {
                this.tool.code = data;
                this.reader = false;
            },

            onError(data) {
                console.log(data);
                alert("Dozvolite korišćenje kamere ili probajte preko drugog browsera");
            },

            loadCachedNames() {
                this.$store.dispatch('loadToolsNames');
                this.$store.dispatch('loadToolsSubtypesNames');
            }
        },

        mounted() {
            this.setUpListener();
        },

        created() {
            this.initData();
            this.loadAllToolTypes();
            this.loadAllWarehouses();
            this.loadAllWorkers();
            this.loadCachedNames();
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.flex-shrink-3 {
    flex-shrink: 2;
}

.gallery-image {
    position: relative;

}

.gallery-image .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000;
}

.gallery-image:hover .overlay {
    opacity: 0.3;
}

.gallery-image .img-btn-group .btn {
    color: white;
}


.gallery-image .img-btn-group {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    transition: .5s ease;
    opacity: 0;
}

.gallery-image:hover .img-btn-group {
    opacity: 1;
}


.gallery-image .tool-image {
    transition: 0.4s all ease-in-out
}
.gallery-image:hover .tool-image {
    opacity: 0.5;
}

.main-image {
    display: inline-block;

}
</style>