<template>
    <div>

        <div class="row" v-if="toolProfile">
            <div class="table-responsive col-md-6">
                <table class="table mb-0">
                    <tbody>
                        <tr>
                            <th scope="row">Naziv</th>
                            <td>{{toolProfile.name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Identifikacija</th>
                            <td>{{toolProfile.identification}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Tip</th>
                            <td>{{toolProfile.type_obj.name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Pod tip</th>
                            <td>{{toolProfile.sub_type}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Tipski naziv</th>
                            <td>{{toolProfile.code_name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Vrednost</th>
                            <td>{{toolProfile.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive col-md-6">
                <table class="table">
                    <tbody>
                        <tr>
                            <th scope="row">Proizvođač</th>
                            <td>{{toolProfile.manufacturer}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Godina proizvodnje</th>
                            <td>{{toolProfile.year}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Serijski broj</th>
                            <td>{{toolProfile.serial_number}}</td>
                        </tr>
                        <tr>
                            <th scope="row">BAR/QR kod</th>
                            <td>{{toolProfile.code}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Stanje alata</th>
                            <td>{{conditionMap[toolProfile.condition]}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Opis</th>
                            <td>{{toolProfile.description}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>




    </div>
</template>

<script>

    import {mapState} from 'vuex'

    export default {
        computed: {
            ...mapState([
                'user',
                'toolProfile'
            ]),

        },

        data() {
            return {
                conditionMap: {
                    "WORKING": "Ispravno",
                    "BROKEN": "Neispravno",
                    "SPENT": "Rashod"
                }
            }
        }

    }
</script>

<style>

</style>